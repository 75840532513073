import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  Email,
  EmailFull,
  LogoMobile,
  PhoneFull,
  Shop,
} from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Props as StickerProps, Sticker } from 'app/components/Sticker'
import { HeaderContext } from 'app/containers/Header'
import { theme } from 'app/theme'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import disableScroll from 'disable-scroll'
import { Link } from 'gatsby'
import React, { memo, useEffect, useState } from 'react'
import { useContext } from 'react'
import { isMobile } from 'react-device-detect'

import { Props as LanguageItemProps } from './LanguageItem'
import { Languages } from './Languages'
import { Props as MenuItemProps } from './MenuItem'
import { ModalClose } from './ModalClose'
import { ModalIBE } from './ModalIBE'
import { Navigation } from './Navigation'
import { PrimaryMenu } from './PrimaryMenu'
import { Props as PrimaryMenuItemProps } from './PrimaryMenuItem'
import { Toolbars } from './Toolbars'

export interface Props {
  languagesList: LanguageItemProps[]
  menuItems: MenuItemProps[]
  pageID?: string
  primaryMenuItems: PrimaryMenuItemProps[]
  stickerProps?: StickerProps
}

export const Header = memo(function Header({
  languagesList,
  menuItems,
  primaryMenuItems,
  stickerProps,
}: Props) {
  const {
    contactsPageURL,
    IBEID,
    languageCode,
    languagePrefix,
    logo,
    logoSticky,
    siteName,
    syncroStatus,
    phone,
    variant,
  } = useContext(HeaderContext) as any

  const [scroll, setScroll] = useState(false)
  const [modalStatus, setModalStatus] = useState(false)
  const [modalIBEStatus, setModalIBEStatus] = useState(false)

  const contactsPageSlug = contactsPageURL?.find(
    (t: any) => t?.languages_code?.code === languageCode,
  )?.slug

  const contactsRealPageURL = languagePrefix
    ? `/${languagePrefix}/${contactsPageSlug}`
    : `/${contactsPageSlug}`

  useEffect(() => {
    const onScroll = () => {
      setScroll(window.scrollY > 250)
    }

    disableScroll.off()

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        {stickerProps ? <Sticker {...stickerProps} /> : null}

        <Head
          className={
            scroll || variant === 'compact' || variant === 'simple'
              ? 'fixed'
              : ''
          }
          dial={4}
          row
          space="between"
          variant={variant}
        >
          <Hamburger
            className="hamburger-menu"
            dial={5}
            onClick={() => {
              if (!modalStatus) {
                setModalStatus(true)
                if (window.innerWidth > 1023) {
                  disableScroll.on()
                } else {
                  document.body.style.overflow = 'hidden'
                }
              } else {
                setModalStatus(false)
              }
            }}
            row
          >
            <HamburgerLines className="hamburger-lines">
              <HamburgerLine />
              <HamburgerLine />
              <HamburgerLine />
            </HamburgerLines>
          </Hamburger>

          {primaryMenuItems ? (
            <PrimaryMenu
              isSticky={scroll || variant === 'compact' || variant === 'simple'}
              menuItems={primaryMenuItems}
            />
          ) : null}

          {logo ? (
            <Link
              className="logo"
              to={languagePrefix ? `/${languagePrefix}` : '/'}
            >
              {logoSticky ? (
                <Media lessThan="desktopSmall">
                  <LogoMobile />
                </Media>
              ) : null}

              {logo ? (
                <Media greaterThanOrEqual="desktopSmall">
                  <Logo
                    src={
                      scroll || variant === 'compact' || variant === 'simple'
                        ? logoSticky
                        : logo
                    }
                    alt={siteName}
                    width="203"
                    height="160"
                  />
                </Media>
              ) : null}
            </Link>
          ) : null}

          <Media lessThan="desktopSmall">
            <MobileWrap dial={5} row>
              <MobileAnchor
                href={`https://www.simplebooking.it/ibe/minishop/?hid=2481&lang=${
                  languagePrefix || 'it'
                }`}
                target="_blank"
              >
                <Shop />
              </MobileAnchor>

              {phone ? (
                <MobileAnchor
                  href={`tel:${phone}`}
                  onClick={() => {
                    if (isMobile) {
                      typeof window.gtag !== 'undefined' &&
                        window.gtag('event', 'click', {
                          event_category: 'Website',
                          event_label: 'Phone Number',
                        })
                    }
                  }}
                >
                  <PhoneFull />
                </MobileAnchor>
              ) : null}
            </MobileWrap>
          </Media>

          <Media greaterThanOrEqual="desktopSmall">
            <Buttons dial={4} row>
              {phone ? (
                <Phone className="phone" dial={4} row>
                  <PhoneFull />
                  {phone}
                </Phone>
              ) : null}

              <ShopCTA
                className="shop-cta"
                href={`https://www.simplebooking.it/ibe/minishop/?hid=2481&lang=${
                  languagePrefix || 'it'
                }`}
                target="_blank"
              >
                <Shop />
                {useVocabularyData('give-away', languageCode)}
              </ShopCTA>

              {contactsPageURL ? (
                <ContactsCTA className="contacts-cta" to={contactsRealPageURL}>
                  <EmailFull />
                  {useVocabularyData('request', languageCode)}
                </ContactsCTA>
              ) : null}

              <CTA
                className="header-cta"
                dial={5}
                onClick={() => {
                  if (!modalIBEStatus) {
                    setModalIBEStatus(true)
                    disableScroll.on()
                  } else {
                    setModalIBEStatus(false)
                    disableScroll.off()
                  }
                }}
              >
                {useVocabularyData('book', languageCode)}
              </CTA>
            </Buttons>
          </Media>
        </Head>

        <Modal className={modalStatus ? 'open' : ''}>
          <ModalClose
            onClick={() => {
              if (window.innerWidth > 1023) {
                disableScroll.off()
              } else {
                document.body.style.overflow = 'auto'
              }
              setModalStatus(false)
            }}
          />
          <Navigation
            onClick={() => {
              if (window.innerWidth > 1023) {
                disableScroll.off()
              } else {
                document.body.style.overflow = 'auto'
              }
              setModalStatus(false)
            }}
            menuItems={menuItems}
            modalStatus={modalStatus}
          />

          <Languages
            languagesList={languagesList}
            languagePrefix={languagePrefix || null}
          />
        </Modal>

        {IBEID && variant === 'default' ? (
          <Modal className={modalIBEStatus ? ' open' : undefined}>
            <ModalClose
              onClick={() => {
                disableScroll.off()
                setModalIBEStatus(false)
              }}
              variant="R"
            />
            <ModalIBE
              onClick={() => {
                disableScroll.off()
                setModalIBEStatus(false)
              }}
              modalStatus={modalIBEStatus}
              syncroStatus={syncroStatus}
            />
          </Modal>
        ) : null}

        <Media lessThan="desktopSmall">
          <Toolbars contactsPageURL={contactsRealPageURL} />
        </Media>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`

const HeaderFixedStyle = css`
  height: 6.25rem;
  background: ${theme.colors.variants.neutralLight4};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  .hamburger-menu,
  .header-cta {
    height: 6.25rem;
  }
  .logo {
    img {
      top: 1.5625rem;
      width: auto;
      height: 3.125rem;
    }
  }
  .phone,
  .shop-cta,
  .contacts-cta {
    color: ${theme.colors.variants.secondaryDark2};
    &:after {
      background: ${theme.colors.variants.secondaryDark2};
    }

    svg {
      fill: ${theme.colors.variants.secondaryDark2};
    }
  }

  @media (max-width: 1199px) {
    height: 3.75rem;

    .hamburger-menu,
    .header-cta {
      height: 3.75rem;
    }
  }
`

const Head = styled(FlexBox)<HeadProps>`
  width: 100%;
  height: 6rem;
  position: relative;
  &.fixed {
    animation: fadeInTop 0.3s;

    ${HeaderFixedStyle}

    ${({ variant }) => {
      switch (variant) {
        case 'compact':
        case 'simple':
          return css`
            animation: none !important;
          `
      }
    }}
  }

  @media (max-width: 1199px) {
    ${HeaderFixedStyle}

    animation: none !important;
    height: 3.75rem;

    .logo {
      width: 4.0625rem;
      height: 1.875rem;
      position: absolute;
      top: 0.9375rem;
      left: 4.8125rem;
      svg {
        width: 4.0625rem;
        height: 1.875rem;
        fill: ${({ theme }) => theme.colors.variants.primaryLight1};
      }
    }
  }

  @keyframes fadeInTop {
    0% {
      top: -5rem;
    }
    100% {
      top: 0;
    }
  }
`

const Hamburger = styled(FlexBox)`
  width: 6rem;
  height: 6rem;
  background: ${({ theme }) => theme.colors.variants.secondaryDark2};
  cursor: pointer;
  position: relative;
  &:hover {
    .hamburger-lines {
      div {
        &:nth-of-type(3) {
          width: 50%;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    width: 3.75rem;
    height: 3.75rem;
  }
`

const HamburgerLines = styled.div`
  width: 1.875rem;

  @media (max-width: 1199px) {
    width: 1.5rem;
  }
`

const HamburgerLine = styled.div`
  width: 100%;
  height: 0.0625rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  border-radius: 1.25rem;
  transition: 0.3s ease-in-out;
  &:nth-of-type(2) {
    margin: 0.1875rem 0;
  }
`

const Logo = styled.img`
  position: absolute;
  top: 2.9375rem;
  left: 50%;
  transform: translateX(-50%);
`

const MobileWrap = styled(FlexBox)`
  height: 3.75rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};

  svg {
    width: auto;
    height: 1rem;
    fill: ${({ theme }) => theme.colors.variants.secondaryDark2};
  }
`

const MobileAnchor = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 3.75rem;
  position: relative;
  &:nth-of-type(2) {
    &:before {
      content: '';
      width: 0.0625rem;
      height: 1.875rem;
      background: ${({ theme }) => theme.colors.variants.secondaryDark2};
      opacity: 0.15;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
`

const Buttons = styled(FlexBox)``

const ButtonStyle = css`
  display: inline-block;
  color: ${theme.colors.variants.neutralLight4};
  font-family: ${theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.01em;
  cursor: pointer;
  margin-right: 1.25rem;
  padding-right: 1.25rem;
  position: relative;
  transition: 0.3s ease-in-out;
  &:hover {
    opacity: 0.7;
  }
  &:after {
    content: '';
    width: 0.0625rem;
    height: 1.875rem;
    background: ${theme.colors.variants.neutralLight4};
    opacity: 0.15;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  svg {
    width: auto;
    height: 1rem;
    fill: ${theme.colors.variants.neutralLight4};
    margin-right: 0.5625rem;
    transform: translateY(0.1875rem);
  }
`

const Phone = styled(FlexBox)`
  ${ButtonStyle}

  svg {
    transform: translateY(0.25rem);
  }
`

const ShopCTA = styled.a`
  ${ButtonStyle}
`

const ContactsCTA = styled(Link)`
  ${ButtonStyle}

  margin-right: 1.875rem;
  padding-right: 0;
  &:after {
    display: none;
  }
`

const CTA = styled(FlexBox)`
  height: 6rem;
  background: ${({ theme }) => theme.colors.variants.primaryLight3};
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 0.9375rem;
  letter-spacing: 0.0469rem;
  line-height: 1.125rem;
  padding: 0 1.5rem;
  text-transform: uppercase;
  transition: 0.2s background ease-in-out;
  &:hover {
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
  }
`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.2s ease-out;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 99;
  }
`

interface HeadProps {
  variant: Variant
}

export type SyncroStatus = 'default' | 'open'

export type Variant = 'default' | 'compact' | 'simple'
